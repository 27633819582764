import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import GoLang from "../assets/img/go.png";
import { Link, useParams } from "react-router-dom";
import { firestore } from "../assets/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default function CommunicationConfirm() {
  const { docId } = useParams();
  const [lead, setLead] = useState(undefined);
  const [code, setCode] = useState(undefined);
  const [secretCode, setSecretCode] = useState(undefined);
  const [isVerified, setVerified] = useState(false);

  const fetchLead = () => {
    if (docId !== undefined) {
      getDoc(doc(firestore, `leads/${docId}`))
        .then((v) => {
          const d = v.data();
          setSecretCode(d["code"]);
          console.log(d);
          setLead(d);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const handleCodeChange = (str) => {
    console.log(str);
    setVerified(Number.parseInt(str) === Number.parseFloat(secretCode));
    console.log("while the secret code is ", secretCode);
    setCode(str);
  };

  useEffect(() => {
    if (lead === undefined && docId !== undefined) {
      fetchLead();
    }
    console.log(code);

    if (isVerified === true) {
      updateDoc(doc(firestore, `leads/${docId}`), {
        verified: isVerified,
      })
        .then((v) => {
          console.log(v);
          setTimeout(() => {
            setVerified(false);
          }, 15000);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isVerified]);

  if (lead === undefined) {
    return <h1>Loading!</h1>;
  }

  if (isVerified) {
    return (
      <Container
        style={{
          minHeight: "90vh",
          flexFlow: "column",
          justifyContent: "center",
          textAlign: "center",
          justifyItems: "center",
          alignItems: "center",
          background: "transparent",
          alignContent: "center",
        }}
      >
        <h1 style={{ textAlign: "center", width: "100%" }}>
          Thank you! A member of our team should reach-out to you.
          <br />
        </h1>
      </Container>
    );
  }

  return (
    <Container style={{ minHeight: "80vh", alignContent: "center" }}>
      <Row style={{ background: "azure" }}>
        <Col
          style={{
            margin: "auto 0",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
            background: "transparent",
          }}
        >
          <img src={GoLang} alt="GoLang Mascot" />
        </Col>
        <Col
          md={6}
          style={{
            textAlign: "center",
            margin: "auto 0",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            justifyItems: "center",
            background: "transparent",
          }}
        >
          <h1>
            Cool! You should be receiving an SMS or Email confirming your
            request. Please enter the Code we sent:
          </h1>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Verification Code:</Form.Label>
            <Form.Control
              type="text"
              style={{ textAlign: "center" }}
              placeholder="*****"
              onChange={(e) => {
                handleCodeChange(e.target.value);
              }}
            />
          </Form.Group>
          <label>
            Was this written in Go, JavaScript (from TypeScript)? Explore our
            tools and services we have used to convince you.
          </label>
          <Link to={"/stack"}>View our stack</Link>
        </Col>
      </Row>
    </Container>
  );
}
