import React from "react";
import GoogleCloud from "../assets/img/gcloud2.png";
import NodeJS from "../assets/img/node.png";

export default function TechStack() {
  return (
    <div style={{ display: "flex", flexFlow: "column", textAlign: "center" }}>
      <label>We specialise in:</label>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <img src={GoogleCloud} height={50} />
        <img src={NodeJS} height={50} />
      </div>
    </div>
  );
}
