import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";

const testAvatar =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt1p6Eeyr48SeTVvKVWaR9fVfcrUUR3CX24g&usqp=CAU";

export default function Profile() {
  return (
    <Container style={{ textAlign: "center", marginTop: "10vh" }}>
      <Row>
        <h2>
          Riaz <b>Hlatshwayo</b>
        </h2>
      </Row>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "yellow",
        }}
        md={12}
        sm={12}
      >
        <Col md={4} sm={12}>
          {/* <h1>Riaz Hlatshwayo</h1> */}
          <img
            src={testAvatar}
            style={{ width: "100%", height: "auto" }}
            alt="Avatar"
          />
        </Col>
        <Col md={4} sm={12}>
          <p>Hi, Riaz here!</p>
          <pre style={{ textAlign: "start", whiteSpace: "pre-wrap" }}>
            <code>
              const developer ={" "}
              {"{" +
                `\n\tnames: "Mohammed Riaz",` +
                `\n\tsurname: "Hlatshwayo",` +
                `\n\tyearsOfExperience: "5+ Years",` +
                "\n}"}
            </code>
          </pre>
        </Col>
      </Row>
    </Container>
  );
}
