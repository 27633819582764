import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Pricing from "./page/Pricing";
import About from "./page/About";
import NavB from "./component/NavBar";
import Footer from "./component/Footer";
import Profile from "./component/Profile/index";
import CommunicationConfirm from "./page/CommunicationConfirm";
import TechStack from "./page/Stack";
import Dome from "./page/Campaign/TheDome";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <h1>Opps! Something is up!</h1>,
  },
  {
    path: "/confirmation/:docId?",
    element: <CommunicationConfirm />,
    errorElement: <h1>Something went wrong</h1>,
  },
  {
    path: "/stack",
    element: <TechStack />,
  },
  {
    path: "/campaign",
    element: <Dome />,
  },
  {
    path: "/profile/riaz",
    element: <Profile />,
    errorElement: <h1>Oops! Something is down!</h1>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NavB />
    <RouterProvider router={router} />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
