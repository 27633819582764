import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoIcon from "../assets/img/bywallof_logo.jpg";
import "../App.css";
import TechStack from "../component/TechStack";

export default function Footer() {
  return (
    <Container fluid style={{ backgroundColor: "whitesmoke" }}>
      <TechStack />
      <Container>
        <h1
          style={{
            width: "100%",
            padding: "30px",
            background: "transparent",
            textAlign: "center",
            fontFamily: "Signika-bold",
            textDecoration: "underline",
          }}
        >
          Why hire when you can delegate?
        </h1>
      </Container>
      <Container>
        <Row style={{ paddingTop: 40, paddingBottom: 40 }}>
          <Col md={4} className="footer-box">
            <img src={logoIcon} width={80} alt="WallOf Logo" />
          </Col>
          <Col md={4} className="footer-box">
            <h3>Contact</h3>
            <label>
              Email:{" "}
              <a href="mailto:business@bywallof.co.za">
                business@bywallof.co.za
              </a>
            </label>
            {/* <label>
              Phone: <a href="tel:+27837651652">083 765 1652</a>
            </label> */}

            {/* <label>WhatsApp: +27 (0) 83 765 1652</label> */}
          </Col>
          <Col md={4} className="footer-box">
            <h3>Company</h3>
            trading as <b>WallOf Digitized Innovation</b> (Pty) Ltd. &copy; 2025
            <br />
            <br />
            <span style={{ color: "gray" }}>
              &#10084; Designed and Developed by our very-own,{" "}
              <b style={{ color: "tomato" }}>Ibrahim Hlatshwayo</b>
            </span>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
