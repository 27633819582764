// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getFirestore, addDoc, collection } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkh3zGUrTLJ2Kjb2xAeN633y8e8RVD17M",
  authDomain: "sourcing-aecbd.firebaseapp.com",
  projectId: "sourcing-aecbd",
  storageBucket: "sourcing-aecbd.firebasestorage.app",
  messagingSenderId: "460927075134",
  appId: "1:460927075134:web:becc5b273c69c974b0226f",
  measurementId: "G-7XZV7YN113",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const fbFunctions = getFunctions(app);

export async function requestQuote(name, email, service, phone) {
  const results = {};
  await addDoc(collection(firestore, "leads"), {
    name: name,
    email: email,
    service: service,
    phone: phone,
    code: generateRandomNumber(),
  })
    .then((value) => {
      results["stuff"] = value.id;
      results["success"] = true;
    })
    .catch((e) => {
      results["error"] = e;
      results["success"] = false;
    });

  return results;
}

function generateRandomNumber() {
  const min = 10000;
  const max = 100000;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
