import React from "react";
import "../App.css";

export default function Information() {
  return (
    <span
      style={{
        width: "100vw",
        background: "black",
        color: "whitesmoke",
        padding: "20px",
      }}
    >
      <label
        style={{
          background: "transparent",
          padding: "15px",
        }}
      >
        <b>25% OFF</b> on 2025 projects.
      </label>
    </span>
  );
}
