import { Container } from "react-bootstrap";
import FancyText from "@carefully-coded/react-text-gradient";

export const icons = [
  {
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1024px-Unofficial_JavaScript_logo_2.svg.png",
    label: "JavaScript",
  },
  {
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png",
    label: "TypeScript",
  },
  {
    icon: "https://seeklogo.com/images/R/react-logo-65B7CD91B5-seeklogo.com.png",
    label: "React",
  },
  {
    icon: "https://www.geekandjob.com/uploads/wiki/a73a9257693d0f4bee6f7a62a5f352eea0937c41.png",
    label: "Google Cloud",
  },
  {
    icon: "https://upload.wikimedia.org/wikipedia/labs/8/8e/Mysql_logo.png",
    label: "MySQL",
  },
  {
    icon: "https://pngimg.com/uploads/github/github_PNG23.png",
    label: "GitHub",
  },
  {
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png",
    label: "Git",
  },
  {
    icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/New_Firebase_logo.svg/1280px-New_Firebase_logo.svg.png",
    label: "Firebase",
  },
  {
    icon: "https://cdn.prod.website-files.com/65264f6bf54e751c3a776db1/66d86964333d11e0a1f1da9e_github_actions.png",
    label: "GitHub Action",
  },
];

export default function TechStack() {
  return (
    <Container
      style={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        justifyItems: "center",
        background: "transparent",
        marginTop: "10vh",
      }}
    >
      {/* <h1>Technology Stack</h1> */}
      <FancyText
        gradient={{ from: "#F858E0", to: "#77156C", type: "linear" }}
        animateTo={{ from: "#6DEDD0", to: "#7AE23A" }}
        animateDuration={2000}
      >
        <label>
          We have built this client-sourcing system using the following services
          and tools:
        </label>
      </FancyText>
      <div style={{ minHeight: "auto", textAlign: "center" }}>
        {icons.map((i) => (
          <img
            src={i.icon}
            key={i.label}
            height={60}
            style={{ margin: 20, color: "gray" }}
            alt={i.label}
          />
        ))}
      </div>
    </Container>
  );
}
