import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { requestQuote } from "../assets/firebase";
import { useNavigate } from "react-router-dom";

function ContactUsModal({ children, title = "Request quote" }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [service, setService] = useState("basic");
  const [phoneNumber, setPhone] = useState("");
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const [country, setCountry] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async () => {
    console.log("Attempting to send request!");
    setLoading(true);
    const requestResult = await requestQuote(name, email, service, phoneNumber);
    console.warn(requestResult);
    setSent(true);
    setLoading(false);
    setTimeout(() => {
      setShow(false);
      navigate("/confirmation/" + requestResult.stuff);
    }, 1500);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {title}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get In Touch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Fill in the form
          <Form style={{ marginTop: 20 }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="name"
                placeholder="John Doe"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Email address:</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              {/* <CountryDropdown
          value={country}
          onChange={(val) => this.selectCountry(val)} /> */}
              <Form.Label>Cell number (with Country Code):</Form.Label>
              {/* <Form.Control type="tel" placeholder="+2783 123 4567" onChange={(e)=>{setPhone(e.target.value)}}/> */}
              <PhoneInput
                placeholder="Enter phone number"
                value={phoneNumber}
                defaultCountry="ZA"
                onChange={(e) => {
                  console.log(e);
                  setPhone(e);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Service:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setService(e.target.value);
                }}
              >
                {/* <option>Open this select menu</option> */}
                <option value="basic">Basic Website</option>
                <option value={"maintain"}>Software Maintenance</option>
                <option value="complex">Complex Website</option>
                <option value={"mobile"}>Mobile (Android and/or iOS)</option>
                <option value="platform">{"Platform (as a Service)"}</option>
                <option value={"api"}>
                  {"API (Application Programming Interface)"}
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group style={{ marginTop: 20, textAlign: "center" }}>
              {!sent ? (
                <label>
                  Or you can call/WhatsApp us on:{" "}
                  <span style={{ textDecoration: "underline" }}>
                    +27 (0) 63 724 5602
                  </span>
                </label>
              ) : (
                <label>Loading.. sending your request!</label>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {!loading && "Done"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContactUsModal;
