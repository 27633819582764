import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FancyText from "@carefully-coded/react-text-gradient";
import ClosingText from "./ClosingText";
import TechStack from "../Stack";
import GirlReach from "../../assets/img/image1_0.jpg";
import ContactUsModal from "../../component/ContactUsPage";

export default function Dome() {
  return (
    <Container
      style={{
        textAlign: "center",
        display: "flex",
        flexFlow: "column",
        marginTop: "80px",
        marginBottom: "80px",
        minHeight: "80vh",
      }}
    >
      <FancyText
        gradient={{ from: "#F858E0", to: "#77156C", type: "linear" }}
        animateTo={{ from: "#6DEDD0", to: "#7AE23A" }}
        animateDuration={2000}
      >
        Campaign | <b>#FightingChanceInSoftware</b>
      </FancyText>
      <Row md={12}>
        <Col style={{}} md={12}>
          <h1>
            Your idea deserves a fighting chance. <br />
            <span style={{ fontSize: "85px" }}>Let us bring it to life.</span>
          </h1>
          <ContactUsModal title="Build my system" />
          <TechStack />
        </Col>
      </Row>
    </Container>
  );
}
